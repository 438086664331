import { useStaticQuery, graphql } from "gatsby"

import { flattenNode } from "../../../../services/arrays"

export const useAirtableConsultationFormFields = () => {
  let formFields = useStaticQuery(graphql`
    query AirtableConsultationFormFields {
      allAirtableallFormFields(sort: { fields: data___Order }) {
        nodes {
          data {
            Name
            Label
            Options
            Placeholder
            Form_Field_Type
            Module
            Section
            Validation
            Required
            Order
            Field_Names
            Label
            Unit
            Is_Multiple_Search
            Date_Config
            Consult_Type
            Is_Numeric
            Required
            Min
            Max
          }
        }
      }
    }
  `)

  return flattenNode(formFields?.allAirtableallFormFields)
}
