import moment from "moment"
import _ from "lodash"

import { getSignedInUser } from "../components/Auth/services/user"

export const isObjectEmpty = (object) => {
  for (var i in object) return false
  return true
}

export const isBrowser = () => typeof window !== "undefined"

export const matchMedia = (mediaQuery) => {
  if (isBrowser()) {
    const MEDIA_QUERY = window.matchMedia(mediaQuery)
    return MEDIA_QUERY.matches
  }
  return false
}

export const formatString = (object) => {
  if (!object) return ""

  if (typeof object === "object" && object["year"]) return buildDate(object)
  else if (typeof object === "string" || typeof object === "number")
    return object
  else if (typeof object === "object") {
    try {
      let data = []
      if (Object.keys(object).length === 0 && object.length === 0) return "N/A"
      if (!!object.label) return object.label
      object.forEach((item) => {
        if (item.value) data.push(item.value)
        else data.push(item)
      })
      data.sort()
      return data.join(", ")
    } catch (exception) {
      return ""
    }
  } else return "N/A"
}

export const buildDate = ({ year, month, date }) => {
  if (!year) return "N/A"
  const buildMonth = month?.value ? `${month?.value} ` : ""
  const buildDate = date?.value ? `${("0" + date?.value).slice(-2)} ` : ""
  return `${buildMonth}${buildDate}${year}`
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ""
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateId = (length) => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const areAllFieldsOptional = (section) => {
  let sectionIsOptional = true
  section.fields.forEach((field) => {
    if (field.isRequired === true || field.formFieldType[0] === "static") {
      sectionIsOptional = false
    }
  })
  return sectionIsOptional
}

export const isSectionNotRenderable = (section) => {
  let sectionIsRenderable = true
  section.fields.forEach((field) => {
    if (field?.consultType !== null) {
      sectionIsRenderable = false
    }
  })
  return sectionIsRenderable
}

export const isSectionRenderable = (section, consultType) => {
  let sectionIsRenderable = false
  section.fields.forEach((field) => {
    if (field?.consultType !== (consultType === null ? null : consultType)) {
      sectionIsRenderable = true
    }
  })
  return sectionIsRenderable
}

export const getElapsedTime = ({
  startTime = moment(),
  endTime = moment(),
}) => {
  let duration = moment.duration(endTime.diff(startTime))
  let elapsedHours = duration.asHours()?.toFixed(0)
  let elapsedMinutes = (duration.asMinutes() % 60).toFixed(0)

  return `${elapsedHours}h ${elapsedMinutes}m `
}

export const renderStringValue = ({ value = "" }) => {
  switch (true) {
    case typeof value === "string":
      return value || "N/A"
    case typeof value === "object":
      return value?.label || "N/A"
    case typeof value === "object" && value?.label === "":
      return "N/A"
    case typeof value === "object" && !!value[0]?.label:
      return value.map((item) => item.label).join(", ")
    case typeof value === "object" && !!value[0]:
      return value.join(", ") || "N/A"
    default:
      return JSON.stringify(value) || "N/A"
  }
}

export const isHyperlink = (link) => {
  let url
  try {
    url = new URL(link)
  } catch (_) {
    return false
  }
  return url.protocol === "http:" || url.protocol === "https:"
}

export const getAvailableConsultTypes = ({ userPermissions = {} }) => {
  let options = []
  if (userPermissions?.consultTypes?.includes("MGX1SOLCL")) {
    options.push("Physical Health")
    options.push("Teleconsult")
  }

  if (userPermissions?.consultTypes?.includes("MGX1SOLMH"))
    options.push("Mental Health")

  if (userPermissions.consultTypes?.includes("MGX1PBMBL"))
    options.push("Best Life")

  return options
}

export const getConsultType = (consultType) => {
  switch (consultType) {
    case "Mental Health":
      return "MGX1SOLMH"
    case "Best Life":
      return "MGX1PBMBL"
    case "Physical Health":
    case "Teleconsult":
      return "MGX1SOLCL"
    default:
      return null
  }
}

export const isColumnedSummary = (section) => {
  let isSectionColumnedSummary = true
  section.fields.forEach((field) => {
    if (!field?.formFieldType?.includes("static")) {
      isSectionColumnedSummary = false
    }
  })

  let longFieldCount = 0
  if (isSectionColumnedSummary === true) {
    section.fields.forEach((field) => {
      if (field.label[0].length > 50) longFieldCount++
    })

    return longFieldCount > section.fields.length / 2 ? false : true
  } else {
    return false
  }
}

export const isSectionStatic = (section) => {
  let isSectionStatic = true
  section.fields.forEach((field) => {
    if (!field?.formFieldType?.includes("static")) {
      isSectionStatic = false
    }
  })
  return !isSectionStatic
}

export const calculateBirthday = ({ birthday }) => {
  var age = 0
  const formatBirthday =
    birthday.month["value"] +
    "-" +
    birthday.date["value"] +
    "-" +
    birthday.year["value"]
  age = moment().diff(formatBirthday, "years")
  return age
}

export const flattenLabelValueObject = (object) => {
  for (let key in object) {
    // if array, check if items in array are label value pair objects
    if (_.isArray(object[key])) flattenLabelValueObject(object[key])
    // if object
    else if (
      typeof object[key] === "object" &&
      !_.isArray(object[key]) &&
      object[key] !== null
    ) {
      // if it is a label value pair object
      if ("label" in object[key]) object[key] = object[key]["label"]
      else if ("value" in object[key]) object[key] = object[key]["value"]
      // check if object has label value pair objects
      else flattenLabelValueObject(object[key])
    }
  }

  return object
}

export const truncateString = (text, truncateLength) => {
  if (text?.length > truncateLength) {
    return text?.substring(0, truncateLength) + "..."
  } else return text
}

export const getClientName = (consultation) => {
  const { allDates } = getSignedInUser()

  let clientConsultation = allDates.find((event) => {
    return event?.id === consultation?.googleCalendarEventId
  })

  let clientName = {}

  clientName = clientConsultation?.attendees.find((attendee) => {
    return attendee?.email === consultation?.clientEmail
  })

  return clientName?.displayName
}

export const convertBooleanToString = ({ data }) => {
  let tempObject = {}
  Object.keys(data).forEach((key) => {
    switch (data[key]) {
      case true:
        tempObject[key] = "Yes"
        break
      case false:
        tempObject[key] = "No"
        break
      default:
        tempObject[key] = data[key]
    }
  })

  return tempObject
}

export const emptyFollowUpFieldsInitialValues = (field) => {
  switch (field.formFieldType[0]) {
    case "select":
      return { label: "", value: "" }
    case "date":
      return {
        date: { label: "", value: "" },
        month: { label: "", value: "" },
        year: { label: "", value: "" },
      }
    case "multiselect":
      return []
    default:
      return ""
  }
}

export const emptyFollowUpFieldsOnFieldRender = (
  setFieldValue,
  followUpField,
  areFollowUpFieldsDisplayed
) => {
  switch (followUpField.formFieldType[0]) {
    case "select":
      if (areFollowUpFieldsDisplayed)
        setFieldValue(followUpField?.name, { label: "", value: "" })
      else setFieldValue(followUpField?.name, { label: "", value: "" })
      break
    case "date":
      if (areFollowUpFieldsDisplayed)
        setFieldValue(followUpField?.name, {
          date: { label: "", value: "" },
          month: { label: "", value: "" },
          year: { label: "", value: "" },
        })
      else
        setFieldValue(followUpField?.name, {
          date: { label: "", value: "" },
          month: { label: "", value: "" },
          year: { label: "", value: "" },
        })
      break
    default:
  }
  return
}

export const convertToTitleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
}
export const sessionStorageSpace = () => {
  var allStrings = ""
  if (isBrowser()) {
    for (var key in window.sessionStorage) {
      if (window.sessionStorage.hasOwnProperty(key)) {
        allStrings += window.sessionStorage[key]
      }
    }
  }
  return allStrings
    ? 3 + (allStrings.length * 16) / (8 * 1024) + " KB"
    : "Empty (0 KB)"
}
