import _ from "lodash"

export const evaluateReset = function (value) {
  if (_.isString(value)) {
    return ""
  }
  if (_.isArray(value)) {
    return []
  }
  // if (isObjectEmpty(value)) {
  //   return {}
  // }
  if (_.isObject(value)) {
    return isEmptyObject(value) ? {} : _.mapValues(value, evaluateReset)
  }
  if (_.isUndefined(value)) {
    return {}
  }
  return null
}

export const clearObject = (object) => {
  return _.mapValues(object, evaluateReset)
}

function isEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }
  return true
}