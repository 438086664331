// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-admin-mental-health-extractor-index-js": () => import("./../../../src/components/Admin/MentalHealth/Extractor/index.js" /* webpackChunkName: "component---src-components-admin-mental-health-extractor-index-js" */),
  "component---src-components-admin-mental-health-update-index-js": () => import("./../../../src/components/Admin/MentalHealth/Update/index.js" /* webpackChunkName: "component---src-components-admin-mental-health-update-index-js" */),
  "component---src-components-admin-mental-health-upload-index-js": () => import("./../../../src/components/Admin/MentalHealth/Upload/index.js" /* webpackChunkName: "component---src-components-admin-mental-health-upload-index-js" */),
  "component---src-components-auth-auth-js": () => import("./../../../src/components/Auth/Auth.js" /* webpackChunkName: "component---src-components-auth-auth-js" */),
  "component---src-components-consultations-consultation-form-page-index-js": () => import("./../../../src/components/Consultations/ConsultationFormPage/index.js" /* webpackChunkName: "component---src-components-consultations-consultation-form-page-index-js" */),
  "component---src-components-consultations-index-js": () => import("./../../../src/components/Consultations/index.js" /* webpackChunkName: "component---src-components-consultations-index-js" */),
  "component---src-components-dashboard-index-js": () => import("./../../../src/components/Dashboard/index.js" /* webpackChunkName: "component---src-components-dashboard-index-js" */),
  "component---src-components-dashboard-training-materials-index-js": () => import("./../../../src/components/Dashboard/TrainingMaterials/index.js" /* webpackChunkName: "component---src-components-dashboard-training-materials-index-js" */),
  "component---src-components-deputy-add-deputy-index-js": () => import("./../../../src/components/Deputy/AddDeputy/index.js" /* webpackChunkName: "component---src-components-deputy-add-deputy-index-js" */),
  "component---src-components-deputy-employees-js": () => import("./../../../src/components/Deputy/Employees.js" /* webpackChunkName: "component---src-components-deputy-employees-js" */),
  "component---src-components-med-grocer-hr-index-js": () => import("./../../../src/components/MedGrocerHR/index.js" /* webpackChunkName: "component---src-components-med-grocer-hr-index-js" */),
  "component---src-components-patients-index-js": () => import("./../../../src/components/Patients/index.js" /* webpackChunkName: "component---src-components-patients-index-js" */),
  "component---src-components-patients-patient-add-index-js": () => import("./../../../src/components/Patients/PatientAdd/index.js" /* webpackChunkName: "component---src-components-patients-patient-add-index-js" */),
  "component---src-components-patients-patient-edit-index-js": () => import("./../../../src/components/Patients/PatientEdit/index.js" /* webpackChunkName: "component---src-components-patients-patient-edit-index-js" */),
  "component---src-components-patients-patient-profile-index-js": () => import("./../../../src/components/Patients/PatientProfile/index.js" /* webpackChunkName: "component---src-components-patients-patient-profile-index-js" */),
  "component---src-components-schedule-index-js": () => import("./../../../src/components/Schedule/index.js" /* webpackChunkName: "component---src-components-schedule-index-js" */),
  "component---src-components-static-pages-home-index-js": () => import("./../../../src/components/StaticPages/Home/index.js" /* webpackChunkName: "component---src-components-static-pages-home-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

