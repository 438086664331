import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../../../services/context.js"

import { isObjectEmpty } from "services/general"
import { clearObject } from "../../../services/clearObject"

export const initialState = {}

const consultations = {
  SAVE_ACTIVE_CONSULTATIONS: "SAVE_ACTIVE_CONSULTATIONS",
  SAVE_CONSULTATION_DATA: "SAVE_CONSULTATION_DATA",
}

const documents = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  REMOVE_DOCUMENTS: "REMOVE_DOCUMENTS",
}
const session = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const ConsultationReducer = (state, action) => {
  let toBeSaved = {}
  switch (action.type) {
    case consultations.SAVE_CONSULTATION_DATA:
      toBeSaved = {
        ...state,
        ...action.payload,
      }
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case documents.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case documents.SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      }

    case documents.REMOVE_DOCUMENTS:
      return {
        ...state,
        documents: [],
      }

    case consultations.SAVE_ACTIVE_CONSULTATIONS:
      toBeSaved = {
        ...state,
        activeConsultation: { ...state.activeConsultation, ...action?.payload },
      }
      return toBeSaved

    case session.SAVE_CONTEXT_TO_SESSION:
      toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...state, ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }
      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...clearObject(state),
      }

    //   case forms.RESET_DETAILS:
    default:
      return { ...clearObject(state) }
  }
}
