import { camelizeKeys } from "humps"
import { isBrowser } from "services/general"
import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]

    return USER
  }
}

//IMPORTANT: this function builds the data you need from firebase on sign-in
export const getSignedInUser = () => {
  if (isBrowser()) {
    const fauthUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    const authUser = JSON.parse(sessionStorage.getItem(fauthUser))
    const userData = camelizeKeys(
      JSON.parse(sessionStorage.getItem("userData"))
    )

    const patients = JSON.parse(sessionStorage.getItem("patients"))

    const consultations = JSON.parse(sessionStorage.getItem("consultations"))

    const organization = camelizeKeys(
      JSON.parse(sessionStorage.getItem("organization"))
    )

    const allDates = JSON.parse(sessionStorage.getItem("allDates"))

    const medstaff = JSON.parse(sessionStorage.getItem("medstaff"))
    const activeMedstaff = JSON.parse(sessionStorage.getItem("activeMedstaff"))

    const addresses = JSON.parse(sessionStorage.getItem("addresses"))
    const permission = JSON.parse(sessionStorage.getItem("permission"))
    const selectedPatient = JSON.parse(
      sessionStorage.getItem("selectedPatient")
    )

    return {
      authUser,
      userData,
      organization,
      addresses,
      activeMedstaff,
      medstaff,
      patients,
      consultations,
      permission,
      allDates,
      selectedPatient,
    }
  } else return {}
}

export const getUserRole = () => {
  const { userData } = getSignedInUser()
  let parsedRole = ""

  if (userData) {
    let userRole = userData?.roles?.find(
      (role) => role?.subdomain === GATSBY_WEBSITE_URL
    )

    if (userRole) {
      parsedRole = btoa(`${userRole?.role}-${userRole?.status}`)
    }
  }

  return parsedRole
}

export const hasRoles = () => {
  const { userData } = getSignedInUser()

  let hasRoles = false

  if (userData) {
    let activeRole = userData?.roles?.find(
      (role) =>
        role?.subdomain === GATSBY_WEBSITE_URL && role?.status === "verified"
    )
    if (activeRole) hasRoles = true
  }

  return hasRoles
}

export const isEmailVerified = () => {
  const { authUser } = getSignedInUser()

  if (authUser && authUser.emailVerified) {
    return true
  }
  return false
}
