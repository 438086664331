import React, { useReducer } from "react"

import { PatientReducer } from "./PatientReducer"
import { useAirtablePatientFormFields } from "./hooks/useAirtablePatientFormFields"

import { generateInitialValues } from "../../../services/context"

const PatientContext = React.createContext()

const PatientProvider = ({ children }) => {
  let formFields = useAirtablePatientFormFields()

  let [patientState, patientDispatch] = useReducer(PatientReducer, {
    ...generateInitialValues({ fields: formFields }),
    // patientState: { ...formFields },
  })

  return (
    <PatientContext.Provider value={{ patientState, patientDispatch }}>
      {children}
    </PatientContext.Provider>
  )
}

export { PatientContext, PatientProvider }
