import React, { useReducer } from "react"

import { ConsultationReducer } from "./ConsultationReducer"
import { useAirtableConsultationFormFields } from "./hooks/useAirtableConsultationFormFields"

import { generateInitialValues } from "../../../services/context"

const ConsultationContext = React.createContext()

const ConsultationProvider = ({ children }) => {
  let formFields = useAirtableConsultationFormFields()
  let [consultationState, consultationDispatch] = useReducer(
    ConsultationReducer,
    {
      documents: [],
      ...generateInitialValues({ fields: formFields }),
      consultationState: { ...formFields },
    }
  )

  return (
    <ConsultationContext.Provider
      value={{ consultationState, consultationDispatch }}
    >
      {children}
    </ConsultationContext.Provider>
  )
}

export { ConsultationContext, ConsultationProvider }
