import React from "react"

import { AppProvider } from "./src/context/AppContext"
import { PatientProvider } from "./src/components/Patients/PatientContext/PatientContext.js"
import { ConsultationProvider } from "./src/components/Consultations/ConsultationContext/ConsultationContext.js"

import { getFirebase } from "services/firebase/firebase"
import "./static/styles/global.scss"

export const wrapRootElement = ({ element }) => {
  getFirebase()

  return (
    <AppProvider>
      <ConsultationProvider>
        <PatientProvider>{element}</PatientProvider>
      </ConsultationProvider>
    </AppProvider>
  )
}
